import { configureScope, init } from '@sentry/browser';
import { Dedupe } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import { isClient } from '../reactjs/components/utility';
import isJSDebugMode from './isJSDebugMode';

const sentryDSN = process.env.SENTRY_DSN_F2E;
const commit = process.env.GIT_COMMIT;

if (sentryDSN && isClient()) {
  init({
    debug: isJSDebugMode,
    dsn: sentryDSN,
    environment: process.env.NODE_ENV,
    // Disable Breadcrumbs if in Development
    integrations:
      process.env.NODE_ENV === 'development'
        ? (integrations) => {
            return integrations.filter(
              (integration) => integration.name !== 'Breadcrumbs'
            );
          }
        : [new Dedupe(), new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
    release: commit,
    allowUrls: [/https?:\/\/[^/]+icook\.(hk|tw|network)\//],
    // Mostly copied from https://gist.github.com/impressiver/5092952
    ignoreErrors: [
      // Webpack loading issues
      'ChunkLoadError',
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      "Can't find variable: _AutofillCallbackHandler",
      "Can't find variable: _pcmBridgeCallbackHandler",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
      // This can be removed once Chrome 89 is widely used
      '-webkit-details-marker',
      "null is not an object (evaluating 'e.contentWindow.postMessage')"
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ]
  });

  const node = document.querySelector('[data-key="icook-current-user"]');
  if (node) {
    const LEFT = '<!--';
    const RIGHT = '-->';
    let currentUser = node.innerHTML;
    currentUser = JSON.parse(
      currentUser.slice(LEFT.length, currentUser.length - RIGHT.length)
    );
    configureScope((scope) => {
      scope.setUser({
        username: currentUser.username,
        is_admin: currentUser.is_admin
      });
    });
  }
}
