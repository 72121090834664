function webviewTrack(name, parameters) {
  return () => {
    if (window.AnalyticsWebInterface) {
      // Call Android interface
      window.AnalyticsWebInterface.logEvent(name, parameters);
    } else if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.firebase
    ) {
      // Call iOS interface
      const message = { command: 'logEvent', name, parameters };
      window.webkit.messageHandlers.firebase.postMessage(message);
    }
  };
}

const bindTrack = () => {
  const trackedAnchors = Array.from(
    document.querySelectorAll('.webview-track')
  );
  trackedAnchors.forEach((anchor) => {
    const name = anchor.getAttribute('data-name');
    const parameters = anchor.getAttribute('data-parameters');

    if (name && parameters) {
      const trackFunc = webviewTrack(name, parameters);
      anchor.addEventListener('click', trackFunc);
    }
  });
};

if (typeof window !== 'undefined') {
  bindTrack();
}

export { webviewTrack };
