import { ensureInitialized } from '@polydice/icook-facebook-init-js/dist/sdk';
import { checkIsUserLoggedIn } from '@polydice/icook-devise-unify';
import { captureException } from '@sentry/browser';

const createElementFromHTML = (html: string) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  if (!div.firstElementChild) throw new Error('Unexpected response');
  return div.firstElementChild;
};

const replaceMatchedElement = (html: string) => {
  const newElement = createElementFromHTML(html);
  const matchedElement = document.getElementById(newElement.id);

  if (!matchedElement) return;

  matchedElement.parentNode?.replaceChild(newElement, matchedElement);
};

interface Data {
  icook_navbar: string;
  navbar_right_mobile_popup: string;
}

interface ErrorData {
  error: string;
}

const statusChangeCallback = async (response: fb.StatusResponse) => {
  const isAutoLogin = !/prevent_auto_login=/.test(document.cookie);

  if (
    response.status === 'connected' &&
    !checkIsUserLoggedIn() &&
    isAutoLogin
  ) {
    const res = await fetch('https://icook.tw/users/auth/facebook/callback', {
      headers: { accept: 'application/json' },
      credentials: 'include'
    });

    /* Bypass the Errors that are handled by server. */
    if (res.status === 501 || res.status === 403) {
      captureException(((await res.json()) as ErrorData).error);
      return;
    }
    try {
      if (!res.ok) throw new Error(`res.status: ${res.status}`);

      const { icook_navbar, navbar_right_mobile_popup } =
        (await res.json()) as Data;

      [icook_navbar, navbar_right_mobile_popup].forEach(replaceMatchedElement);
    } catch (e) {
      if (e instanceof Error) {
        // Errors may happen if server doesnt response as expected.
        captureException(`Facebook auto login failed: ${e.message}`);
      }
    }
  }
};

ensureInitialized(() => FB.getLoginStatus(statusChangeCallback));
