import { createElement } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import Snackbar from '@polydice/ui/dist/Snackbar';
import supportsPassive from '../../components/supportsPassive';
import dayjs from 'dayjs';

export const storageKey = 'iCookShoppingCart';

export const isMobile = () => /Mobi/.test(navigator.userAgent);

export const isClient = () => typeof window !== 'undefined' && window.document;

export const preventDefault = (e) => {
  if (e.cancelable) {
    e.preventDefault();
  }
};

export const fixBody = (action) => {
  if (action) {
    document.body.classList.add('modal-open');
    document.body.addEventListener(
      'touchmove',
      preventDefault,
      supportsPassive
        ? {
            passive: true
          }
        : false
    );
  } else {
    document.body.classList.remove('modal-open');
    document.body.removeEventListener('touchmove', preventDefault);
  }
};

export const scrollTo = (
  element,
  options = {
    behavior: 'smooth',
    block: 'start'
  }
) => {
  if (!element) return;
  try {
    element.scrollIntoView(options);
  } catch {
    element.scrollIntoView(true);
  }
};

export const hideFacebookDialog = (action) => {
  const facebookDialog = document.getElementById('CustomerChat');
  if (facebookDialog) {
    facebookDialog.style.display = action ? 'none' : 'block';
    return true;
  }
  return false;
};

export const getAuthToken = () =>
  document.querySelector('[name="csrf-token"]').getAttribute('content');

export const isCool3c = () => {
  return window.location.href?.includes('market.cool3c');
};

export const showError = (show) => {
  const container = document.getElementById('error-container');
  if (container) {
    if (show) {
      render(
        // eslint-disable-next-line react/no-children-prop
        createElement(Snackbar, {
          type: 'error',
          children: '網路似乎不穩定，請稍後嘗試'
        }),
        container
      );
    } else {
      unmountComponentAtNode(container);
    }
  }
};

export const formatter = (number) =>
  number.toString().replace(/\d(?=(\d{3})+$)/g, '$&,');

export const getFareDescription = (product) => {
  const { fare, fareThreshold } = product;
  let fareDescription = '';
  if (!fare) {
    fareDescription = '本館免運費';
  } else if (fareThreshold) {
    fareDescription = `消費滿 ${fareThreshold} 元免運費`;
  } else {
    fareDescription = '本館無免運費優惠';
  }
  return fareDescription;
};

export const getAvailableQuantity = (sku) => {
  const inventory = Math.min(
    sku.available_inventory_count,
    sku.quantity_ceiling
  );
  return Math.max(inventory, 0);
};

const getProgressiveRedemptionDiscount = ({
  discountThreshold,
  discount,
  productsTotal
}) => {
  let total = productsTotal;
  let times = 0;
  while (total >= discountThreshold) {
    total -= discountThreshold;
    times += 1;
  }
  return times * discount;
};

const getShippingDiscount = ({ fareTotal, discount }) =>
  fareTotal > 0 ? Math.min(fareTotal, discount) : 0;

const getBuySomeGetPercentageDiscount = ({ productsTotal, discount }) =>
  Math.floor(productsTotal * (discount / 100));

export const getTotal = (vip, coupon, fare, fareThreshold, skus) => {
  const orderSkus = Object.values(skus).map(({ price, quantity }) => ({
    price,
    quantity
  }));

  // productsTotal
  const productsTotal = orderSkus.reduce((sum, orderProduct) => {
    return sum + orderProduct.price * orderProduct.quantity;
  }, 0);

  // fareTotal
  const fareTotal = fareThreshold && productsTotal >= fareThreshold ? 0 : fare;
  // discountTotal
  let discountTotal = 0;
  if (!coupon || (coupon.vip_only && !vip)) {
    discountTotal = 0;
  } else {
    const {
      policy: discountPolicy,
      discount,
      discount_threshold: discountThreshold
    } = coupon;

    if (discountPolicy === 'buy_some_get_percentage_off') {
      const orderProductsTotalQuantity = orderSkus.reduce(
        (sum, { quantity }) => sum + quantity,
        0
      );
      if (orderProductsTotalQuantity >= discountThreshold) {
        discountTotal = getBuySomeGetPercentageDiscount({
          productsTotal,
          discount
        });
      }
    } else {
      if (productsTotal >= discountThreshold) {
        switch (discountPolicy) {
          case 'redemption':
            discountTotal = discount;
            break;
          case 'progressive_redemption':
            discountTotal = getProgressiveRedemptionDiscount({
              discount: coupon.discount,
              productsTotal,
              discountThreshold
            });
            break;
          case 'shipping':
            discountTotal = getShippingDiscount({
              fareTotal,
              discount
            });
            break;
          default:
            discountTotal = 0;
            break;
        }
      } else {
        discountTotal = 0;
      }
    }
  }

  const total = productsTotal + fareTotal - discountTotal;

  return { total, productsTotal, fareTotal, discountTotal };
};

export const getDateStatus = (started_at, ended_at) => {
  const nowDate = dayjs(new Date());
  const startDate = dayjs(started_at);
  const endDate = dayjs(ended_at);

  if (nowDate.isBefore(startDate)) return 'future';
  if (nowDate.isAfter(endDate)) return 'expired';
  return 'active';
};

export const generateFormattedTime = (seconds) => {
  const day = Math.floor(seconds / 3600 / 24);
  if (day >= 2) {
    return { number: day, unit: '天' };
  }
  const hour = Math.floor(seconds / 3600);
  if (hour >= 1) {
    return { number: hour, unit: '小時' };
  }
  const minute = Math.floor(seconds / 60);
  if (minute >= 1) {
    return { number: minute, unit: '分鐘' };
  }
  const second = Math.floor(seconds % 60);
  return { number: second, unit: '秒' };
};

export const getViewportWidth = () => {
  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  );
};

export const debounce = (func, wait = 300, immediate = false) => {
  let timeout;

  return function executedFunction() {
    const context = this;
    const args = arguments;

    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};
