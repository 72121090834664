/* eslint-disable no-console */

import 'navigator.sendbeacon';
import Cookies from 'js-cookie';
import localStorage from 'local-storage-fallback';
import { detect as detectBrowser } from 'detect-browser';
import dayjs from 'dayjs';

const PROVIDER = {
  TTD: 'unified_id'
};
const EXPIRED_FLAGS = {
  TTD: `${PROVIDER.TTD}_exp`
};

const isSafari = () => {
  const browser = detectBrowser();
  return (
    browser &&
    (browser.name === 'safari' ||
      browser.name === 'ios' ||
      browser.name === 'ios-webview')
  );
};

/**
 * Send ad unified ID data to `fluentd.polydice.com`
 *
 * @param {{id: string, provider: string} data
 */
function sendData({ id, provider }) {
  const ENDPOINT = 'https://fluentd.polydice.com/event';
  const generalVariables = {
    tag: 'event.unique_id',
    visitor: Cookies.get('visitor') || '',
    seen_at: Date.now() / 1000,
    id,
    provider
  };

  const form = new FormData();
  form.append('json', JSON.stringify(generalVariables));
  const isSuccess = navigator.sendBeacon(ENDPOINT, form);
  if (!isSuccess) {
    navigator.sendBeacon(ENDPOINT, form);
  }
}

const isExpired = (provider) => {
  const expirationFlag = localStorage.getItem(EXPIRED_FLAGS[provider]);

  if (!expirationFlag) {
    return true;
  }

  const now = dayjs();
  const expiredDate = dayjs(expirationFlag);
  return now.isAfter(expiredDate);
};

/**
 * Set id expiration date on localStorage
 * @param {'TTD'} provider
 */
function setExpiration(provider) {
  const now = dayjs();
  const end = isSafari() ? now : now.add(1, 'year');
  localStorage.setItem(EXPIRED_FLAGS[provider], end.toISOString());
}

async function syncTDID() {
  const PARTNER_ID = 'nkd9bh0';
  const JSON_URL = `https://match.adsrvr.org/track/rid?ttd_pid=${PARTNER_ID}&fmt=json`;

  const res = await fetch(JSON_URL, { credentials: 'include' });
  if (res.ok) {
    const json = await res.json();
    const { TDID } = json;

    localStorage.setItem(PROVIDER.TTD, TDID);
    sendData({ id: TDID, provider: PROVIDER.TTD });
    setExpiration('TTD');
  }
}

function sync() {
  if (isExpired('TTD')) {
    syncTDID();
  }
}

sync();
