let _supportsPassive = false;
try {
  const opts = Object.defineProperty({}, 'passive', {
    get() {
      _supportsPassive = true;
      return false;
    }
  });
  window.addEventListener('testPassive', null, opts);
  window.removeEventListener('testPassive', null, opts);
} catch (e) {
  _supportsPassive = false;
}
const supportsPassive = _supportsPassive;
export default supportsPassive;

// import supportsPassive from path/to/supportsPassive;
// element.addEventListener('touchstart', fn, supportsPassive ? { passive: true } : false);
// ref: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#safely_detecting_option_support
