// for overflow blur effect
const categoriesList = document.querySelector('.categories-list');

if (categoriesList) {
  const blurBefore = document.querySelector('.categories-list__blur--before');
  const blurAfter = document.querySelector('.categories-list__blur--after');

  if (
    categoriesList.scrollLeft !==
    categoriesList.scrollWidth - categoriesList.clientWidth
  ) {
    blurAfter.style.opacity = '1';
  } else {
    blurAfter.style.opacity = '0';
  }

  categoriesList.addEventListener('scroll', (e) => {
    const { scrollLeft, scrollWidth, clientWidth } = e.target;
    const maxScrollLeft = scrollWidth - clientWidth;

    blurBefore.style.opacity = scrollLeft > 1 ? '1' : '0';
    blurAfter.style.opacity = scrollLeft !== maxScrollLeft ? '1' : '0';
  });
}
